<template>
  <div class="flex_column empty_container">
    <template v-if="$slots.default">
      <slot></slot>
    </template>
    <template v-else>
      <img v-if="isEmptyImg" :src="emptyImgSrc" alt="" class="empty_img" />
      <div class="empty_txt">{{ emptyText }}</div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TableEmpty",
  props: {
    /** 暂无数据文案 */
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    /** 是否展示空图片 */
    isEmptyImg: {
      type: Boolean,
      default: true,
    },
    /** 图片资源 */
    emptyImgSrc: {
      type: String,
      default: require("@/assets/image/component/empty-table.png"),
    },
  },
};
</script>

<style scoped>
.flex_column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.empty_container {
  padding: 52px 0;
  justify-content: center;
  line-height: 1;
}
.empty_img {
  width: 200px;
  object-fit: cover;
}
.empty_txt {
  margin: 16px 0;
  font-size: 16px;
  color: #b9bfc8;
}
</style>
