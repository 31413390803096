<template>
  <div>
    <div class="head">
      <div class="head_left">
        <!-- <div
          @click="selectTab(item)"
          v-for="(item, i) in tabList"
          :key="i"
          :class="{ tab_item: true, tab_select: seleceTabId == item.id }"
        >
          {{ item.name }}
        </div> -->
      </div>
      <div class="search_box">
        <el-input
          v-model.trim="searchValue"
          placeholder="请输入搜索关键字"
          @keydown.enter.native="keySearch"
        ></el-input>
        <span class="search_icon el-icon-search" @click="keySearch"></span>
      </div>
    </div>
    <div class="list" v-loading="loading">
      <div class="list_left">
        <template v-if="list1.length > 0">
          <div
            v-for="(item, i) in list1"
            :key="i"
            class="list_item"
            @click="toDetail(item.id)"
          >
            <div class="item_time">
              <div class="item_time_data">{{ item.time[2] }}</div>
              <div>{{ item.time[0] + "." + item.time[1] }}</div>
            </div>
            <img :src="item.contentImgUrl || $defaultImg" class="item_img" />
            <div class="item_content">
              <div class="item_content_head">
                {{ item.contentTitle }}
              </div>
              <div class="item_content_info">
                {{ item.contentDescription }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            style="
              height: 600px;
              display: flex;
              align-content: center;
              justify-content: center;
            "
          >
            <BaseTableEmpty></BaseTableEmpty>
          </div>
        </template>
      </div>
      <!-- <div class="list_right">
        <div
          class="right_item"
          v-for="(item, i) in list2"
          :key="i"
          @click="toDetail(item.id)"
        >
          <img class="right_item_img" :src="item.coverImage.url" alt="" />
          <div class="right_item_bot">
            <div class="right_bot_head">足球城市联盟</div>
            <div class="right_bot_title">
              {{ item.contentTitle }}
            </div>
            <div class="right_bot_time">{{ item.contentDatetime }}</div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        :current-page="pageNum"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import BaseTableEmpty from "@/components/BaseTableEmpty/index.vue";
import { getList } from "@/api/login";
import { time } from "@/utils/image";
export default {
  name: "annouce",
  components: { BaseTableEmpty },
  data() {
    return {
      rightId: null,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      searchValue: "",
      list1: [],
      list2: [],
    };
  },
  created() {
    this.getList1();
  },
  methods: {
    async getList1() {
      try {
        this.loading = true;
        let res = await getList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          categoryCode: "notice",
          contentTitle: this.searchValue,
        });
        this.loading = false;
        if (res.code == "0") {
          let arr = res.data.cmsContents.dataList || [];
          arr.forEach((item) => {
            item.time = time(item.contentDatetime);
          });
          this.list1 = arr;
          this.total = res.data.cmsContents.total;
        } else {
          this.list1 = [];
          this.total = 0;
        }
      } catch (error) {
        this.list1 = [];
        this.total = 0;
        this.loading = false;
      }
    },
    keySearch() {
      this.pageNum = 1;
      this.getList1();
    },
    handleSizeChange(size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.getList1();
    },
    handleCurrentChange(page) {
      this.pageNum = page;
      this.getList1();
    },
    toDetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped>
.head {
  background-color: #d80c24;
  height: 0.52rem;
  padding-left: 1.06rem;
  padding-right: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head_left {
  display: flex;
  align-items: center;
}
.search_box {
  position: relative;
  width: 3.25rem;
  height: 0.36rem;
  border-radius: 0.18rem;
  overflow: hidden;
}
.search_box /deep/ .el-input__inner {
  height: 0.36rem;
  padding-right: 50px;
}
.search_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.16rem;
  font-size: 0.18rem;
  cursor: pointer;
}
.tab_item {
  margin-right: 0.4rem;
  color: #fefefe;
  padding: 0.1rem 0.32rem;
  cursor: pointer;
  border-bottom: 3px solid #d80c24;
}
.tab_select {
  border-bottom: 3px solid #ffffff;
}

.list {
  padding: 0.3rem 0.7rem;
  display: flex;
  justify-content: space-between;
  min-height: 500px;
}
.list_left {
  flex: 1;
}
.list_item {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  cursor: pointer;
}
.item_time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d80c24;
  width: 0.84rem;
  height: 0.88rem;
  color: #d80c24;
  border-radius: 4px;
}
.item_time_data {
  font-size: 34px;
  line-height: 48px;
}
.item_img {
  width: 1.56rem;
  height: 0.88rem;
  margin: 0 10px 0 20px;
}
.item_content {
  height: 0.88rem;
}
.item_content_head {
  font-size: 16px;
  font-weight: 700;
  color: #3e3a39;
  width: 4.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.15rem;
}
.item_content_info {
  color: #595959;
  width: 5.8rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.list_right {
  display: flex;
  flex-direction: column;
}
.right_item {
  width: 2.5rem;
  height: 3.4rem;
  position: relative;
  margin-bottom: 0.2rem;
  z-index: 1;
  cursor: pointer;
}
.right_item_img {
  width: 100%;
  height: 100%;
}
.right_item_bot {
  position: absolute;
  background: url(../../assets/image/page/annouce1.png) no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.55rem;
}
.right_bot_head {
  height: 0.35rem;
  line-height: 0.35rem;
  text-align: right;
  padding-right: 0.2rem;
  color: #3e3a39;
  margin-bottom: 0.15rem;
}
.right_bot_title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-left: 0.2rem;
  width: 2.1rem;
  color: #f9f9f9;
  font-size: 16px;
}
.right_bot_time {
  color: #f9f9f9;
  margin-top: 0.1rem;
  margin-left: 0.2rem;
}

.page_box {
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
}
.page_box
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #d80c24;
}
</style>
