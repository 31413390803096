export function img(img) {
  let obj = JSON.parse(JSON.stringify(img));
  let url = process.env.VUE_APP_BASE_URL + img.path;
  obj.url = url;
  return obj;
}

export function imageUrlHander(content) {
  var reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; //img 标签取src里面内容的正则
  var srcArr = content.match(reg)||[]; //取到所有img标签放到数组中
  if (srcArr.length) {
    for (let i = 0; i < srcArr.length; i++) {
      var srcImg = srcArr[i].replace(reg, "$1");
      // console.log(srcImg, "获取到的地址");
      var url = process.env.VUE_APP_BASE_URL + srcImg;
      // console.log(url, "新地址");
      var newImg = srcArr[i].replace(srcImg, url);
      content = content.replace(srcArr[i], newImg);
    }
  }
  return content;
}

export function time(str) {
  let t = str.split(" ")[0];
  let year = t.split("-")[0];
  let month = t.split("-")[1];
  let day = t.split("-")[2];
  return [year, month, day];
}
